<template>
    <div>
        <!-- <b-card title="Kick start your project 🚀">
            <b-card-text>All the best for your new project.</b-card-text>
            <b-card-text>Please make sure to read our
                <b-link
                    href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
                    target="_blank"
                >
                    InteGreat Oversigt
                </b-link> to understand where to go from here and how to use our template.
            </b-card-text>
        </b-card>

        <b-card title="Want to integrate JWT? 🔒">
            <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
            <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
        </b-card> -->
        <div class="dashboard_content">
          <b-card title="E-conomic ">
             <b-card-text>Under e-conomic finder finder du alle debitorer i e-conomic.</b-card-text>
             <b-card-text>Debitorer viser oplysninger fra economic om dine debitorer. Her kan du se dem med deres udestående (under balance), og forfaldent beløb..</b-card-text>
             <b-card-text>Når du klikker på kundenummeret, kan du se yderlige oplysninger om kunden.</b-card-text>
             <b-card-text>Det er muligt at filtrere på delvis angvelse af navn og/eller kundenummer</b-card-text>
          </b-card>
          <b-card title="Kundeportal 🔒">
            <b-card-text>Under kundeportal finder du alle debitorer med deres adgangsstatus.</b-card-text>
            <b-card-text>Mangler du kunder? Tryk på 'opdater debitorer', og vi henter dem. Det kan tage lang tid at hente!</b-card-text>
            <b-card-text>Alle grønne debitorer har adgang til kundeportalen, alle røde debitorer har ikke adgang.</b-card-text>
            <b-card-text>Du kan nemt ændre en kundens adgang ved at vælge en eller flerekunder, og trykke på actions bjælke.
              Du har disse muligheder:</b-card-text>
            <b-card-text>Deaktivere: kunden har ikke adgang mere til kundeportalen</b-card-text>
            <b-card-text>Aktivere: kunden har adgang (igen) til kundeportalen.</b-card-text>
            <b-card-text>Aktivere og email: Kunden bliver aktiveret på kundeportalen, og får tilsendt en email med login/password oplysninger (samme oplysninger som tidligere tilsendt).</b-card-text>
            <b-card-text>Aktivere, ny adgangskode og email: Kunden bliver aktiveret på kundeportalen, og får tilsendt en email med nye login/password oplysninger.</b-card-text>
          </b-card>
        </div>

    </div>
</template>

<script>
    import { BCard, BCardText, BLink } from 'bootstrap-vue';
    import axios from "axios";

    export default {
        components: {
            BCard,
            BCardText,
            BLink,
        },
        data(){
            return{
            }
        },
        created(){
            this.mytoken = localStorage.getItem("token");

            if (this.mytoken == "" || this.mytoken == null) {
                localStorage.removeItem("token");
                this.$router.push({ name: "login" });
            }else{
                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/checktoken/"+this.mytoken
                };
                axios(config)
                .then((response) => {
                    if(response.data.token.status == true){
                        console.log('home');
                    }
                })
                .catch(function (error) {
                    if(error.response.status == 404)
                    {
                        localStorage.setItem("token", '');
                        localStorage.removeItem("username");
                        window.location.reload();
                    }
                });
            }
        },
        
    }
</script>

<style scope>

.dashboard_content{
    width:1000px;
    margin-left:100px;
    text-align:left;
}

</style>
