import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../libs/i18n/index' // import VueI18n instance

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [{
            path: '/',
            name: 'home',
            component: () =>
                import ('@/views/Home.vue'),
            meta: {
                pageTitle: 'Dashboard',
                breadcrumb: [{
                    text: 'Dashboard',
                    active: true,
                }, ],
            },
        },
        {
            path: '/registration',
            name: 'registration',
            component: () =>
                import ('@/views/Registration.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/registration/:key',
            name: 'registration',
            component: () =>
                import ('@/views/Registration.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/acceptance-page',
            name: 'acceptance-page',
            props: true,
            component: () =>
                import ('@/views/acceptance-page.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () =>
                import ('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
            props: true
        },
       
        {
            path: '/debtors',
            name: 'debtors',
            component: () =>
                import ('@/views/debtors.vue'),
            meta: {
                pageTitle: 'Debtors',
                breadcrumb: [{
                    text: 'Debtors',
                    active: true,
                }, ],
            },
        },
        {
            path: '/economic-debtor-details/:customernumber',
            name: 'economic-debtor-details',
            component: () =>
                import ('@/views/economic-debtor-details.vue'),
            meta: {
                pageTitle: 'Economic Debtor Details',
                breadcrumb: [{
                    text: 'Economic Debtor Details',
                    active: true,
                }, ],
            },
        },
        {
            path: '/debtor-list',
            name: 'debtor-list',
            component: () =>
                import ('@/views/debtor-list.vue'),
            meta: {
                pageTitle: 'Debtor List',
                breadcrumb: [{
                    text: 'Debtor List',
                    active: true,
                }, ],
            },
        },
        {
            path: '/debtor-details/:customerID',
            name: 'debtor-details',
            component: () =>
                import ('@/views/debtor-details.vue'),
            meta: {
                pageTitle: 'Debtor Details',
                breadcrumb: [{
                    text: 'Debtor Details',
                    active: true,
                }, ],
            },
        },
        {
            path: '/invoice-list/:customerID',
            name: 'invoice-list',
            component: () =>
                import ('@/views/invoice-list.vue'),
            meta: {
                pageTitle: 'Invoice List',
                breadcrumb: [{
                    text: 'Invoice List',
                    active: true,
                }, ],
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () =>
                import ('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
});

export default router