<template>
    <div>
        <b-card
            no-body
            class="mb-0"
        >
            <div class="m-2">
                <b-row>
                    <b-col
                        cols="12"
                        md="8"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <b-form-input
                            v-model="filterName"
                            id="filterName"
                            class="d-inline-block mr-1"
                            :placeholder="$t('Name')"
                        />
                        <b-form-input
                            v-model="filterNumber"
                            id="filterNumber"
                            class="d-inline-block mr-1"
                            :placeholder="$t('Number')"
                        />
                    </b-col>
                </b-row>
                <br>
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label>{{ $t('Pagesize') }}</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <!-- <label class="mr-50">{{ $t('Action') }}</label>
                        <v-select
                            v-model="filterselectedCustomer"
                            dir="ltr"
                            :options="actionOptions"
                            @input ="multipleAction($event)"          
                            class="invoice-filtercheck-select"
                            :placeholder="$t('Actions')"
                        >
                            <template #selected-option="{ label }">
                            <span class="text-truncate overflow-hidden">
                                {{ label }}
                            </span>
                            </template> </v-select
                        >&nbsp; -->
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalInvoices"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="invoicesdata"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
            >
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50">{{ $t('Details') }}</span>
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                        </b-dropdown-item>

                        <b-dropdown-item>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                        </b-dropdown-item>

                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select';
    import axios from "axios";
    import i18n from '@/libs/i18n/index';

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,

            vSelect,
        },
        data() {
            return {
                invoicesdata : [],
                tableColumns : [
                    { key: 'Customer_ID', label: i18n.t('Customer_ID'), sortable: true },
                    { key: 'debtorAccount', label: i18n.t('debtorAccount'), sortable: true },
                    { key: 'debtorName', label: i18n.t('debtorName'), sortable: true },
                    { key: 'kundeLogin', label: i18n.t('kundeLogin'), sortable: true },
                    { key: 'KundePin', label: i18n.t('KundePin'), sortable: true },
                    { key: 'actions', label: i18n.t('actions') },
                ],
                perPage: 10,
                currentPage: "1",
                totalInvoices: "",
                dataMeta: { from: 1, to: this.perPage, of: this.totalInvoices },
                perPageOptions: [10, 20, 40, 60, 80, 100],
                filterName : "",
                filterNumber:""
            }
        },
        updated() {
            const f = this.perPage * (this.currentPage - 1);
            this.dataMeta.from = f + 1;
            this.dataMeta.to = this.perPage * this.currentPage;
        },
        watch: {
            perPage: function () {
                //this.$refs.refInvoiceListTable.refresh();
                this.getDebtorlist();
            },
            filterNumber: function (val) {
                this.filterNumber = val;
                this.getDebtorlist();
            },
            filterName: function (val) {
                this.filterName = val;
                this.getDebtorlist();
            },
            currentPage: function (val) {
                this.currentPage = val;
                this.getDebtorlist();
            },
        },
        created(){
            this.mytoken = localStorage.getItem("token");

            if (this.mytoken == "" || this.mytoken == null) {
                localStorage.removeItem("token");
                this.$router.push({ name: "login" });
            }else{
                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/checktoken/"+this.mytoken
                };
                axios(config)
                .then((response) => {
                    if(response.data.token.status == true){
                        this.getInvoicelist();
                    }
                })
                .catch(function (error) {
                    if(error.response.status == 404)
                    {
                        localStorage.setItem("token", '');
                        localStorage.removeItem("username");
                        window.location.reload();
                    }
                });
            }
        },
        methods : {
            getInvoicelist(){
                this.mytoken = localStorage.getItem("token");

                const offset = this.perPage * (this.currentPage - 1);
                this.filteroffset = offset;

                // var config = {
                //     method: "get",
                //     url: "https://engine.netsupport.dk:7080/account/v1/headers/" + this.mytoken +
                //     "?offset=" + this.filteroffset +
                //     "&pagesize=" + this.perPage +
                //     "&name=" + this.filterName +
                //     "&number=" + this.filterNumber +
                //     "&status=" + this.filterStatus 
                // };
                // axios(config)
                // .then((response) => {
                //     this.dataMeta.of = response.data.debtors.MaxRecords;
                //     this.totalDebtor = response.data.debtors.MaxRecords;
                //     this.debtordata = JSON.parse(
                //         JSON.stringify(response.data.debtors.recordset)
                //     );
                // })
                // .catch(function (error) {
                //     console.log(error);
                // });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
    .check-btn{
        transform: scale(02);
        width: 12px;
        height: 12px;
    }
    .invoice-filtercheck-select{
        width: 40%;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
