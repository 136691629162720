<template>
    <section class="invoice-preview-wrapper">

        <b-row class="invoice-preview">
            
            <b-col
                cols="12"
            >
                <b-card
                    no-body
                    class="invoice-preview-card"
                >
                    <!-- Header -->
                    <b-card-body class="invoice-padding pb-0">

                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <!-- <vuexy-logo /> -->
                                    <b-img fluid :src="logoUrl" alt="Login" class="login_logo_img"/>
                                    <h3 class="text-primary invoice-logo">
                                        {{ $t('Portal Manager') }}
                                    </h3>
                                </div>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-2">
                                <h4 class="invoice-title">
                                    {{ $t("Customer Number") }} : 
                                    <span class="invoice-number"># {{ customernumber }}</span>
                                </h4>
                            </div>
                        </div>
                    </b-card-body>
                    <b-card-body class="invoice-padding pt-0">
                        <hr>
                            <b-row class="custom-spacing">
                                <p class="info-heading">{{ $t('Economic Debtor Details') }}</p>
                            </b-row>
                        <hr>
                    </b-card-body>
                    <b-card-body class="invoice-padding pt-0">
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("Customer Name") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.name }}
                                </b-card-text>    
                            </b-col>
                        </b-row>
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("Email") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.email }}
                                </b-card-text>    
                            </b-col>
                        </b-row>
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("Address") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.address }}<br>
                                    {{ debtordata.city }}, {{ debtordata.zip }}<br>
                                    {{ debtordata.country }}
                                </b-card-text>    
                            </b-col>
                        </b-row>
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("Currency") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.currency }}
                                </b-card-text>    
                            </b-col>
                        </b-row>
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("Balance") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.balance }}
                                </b-card-text>    
                            </b-col>
                        </b-row>
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("Due-Amount") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.dueAmount }}
                                </b-card-text>    
                            </b-col>
                        </b-row>
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("Telephone/FaxNumber") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.telephoneAndFaxNumber }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("Corporate Identification Number") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.corporateIdentificationNumber }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                        <b-row class="custom-spacing">
                            <b-col
                                cols="12"
                                xl="3"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ $t("E-invoiceing Disabled ByDefault") }} :
                                </b-card-text>
                            </b-col>
                            <b-col
                                cols="12"
                                xl="9"
                                class="p-0"
                            >
                                <b-card-text class="font-weight-bold mb-25">
                                    {{ debtordata.eInvoicingDisabledByDefault }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>
<script>
    import axios from "axios";
    import {
        BRow, 
        BCol, 
        BCard, 
        BCardBody, 
        BTableLite, 
        BCardText, 
        BButton, 
        BAlert, 
        BLink, 
        VBToggle,
        BImg,
    } from 'bootstrap-vue'
    import Logo from '@core/layouts/components/Logo.vue';
    import Ripple from 'vue-ripple-directive';
    import VuexyLogo from "@core/layouts/components/Logo.vue";
    import store from "@/store/index";

    export default {
        directives: {
            Ripple,
            'b-toggle': VBToggle,
        },
        components: {
            BRow,
            BCol,
            BCard,
            BCardBody,
            BTableLite,
            BCardText,
            BButton,
            BAlert,
            BLink,
            BImg,
            Logo,
            VuexyLogo
        },
        data() {
            return{
                mytoken: "",
                debtordata : [],
                customernumber : this.$route.params.customernumber,
                logoImg : require("@/assets/images/pages/login_custom_dark.png")
            }
        },
        computed: {
            logoUrl(){
                if (store.state.appConfig.layout.skin === "dark") {
                    this.logoImg = require("@/assets/images/pages/login_custom_dark.png");
                    return this.logoImg;
                }
                return this.logoImg;   
            }
        },
        created() {
            this.mytoken = localStorage.getItem("token");

            if (this.mytoken == "" || this.mytoken == null) {
                localStorage.removeItem("token");
                this.$router.push({ name: "login" });
            }else{
                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/checktoken/"+this.mytoken
                };
                axios(config)
                .then((response) => {
                    if(response.data.token.status == true){
                        this.getdebtordata();
                    }
                })
                .catch(function (error) {
                    if(error.response.status == 404)
                    {
                        localStorage.setItem("token", '');
                        localStorage.removeItem("username");
                        window.location.reload();
                    }
                });
            }
        },

        methods: {
            
            getdebtordata() {
                this.mytoken = localStorage.getItem("token");

                axios(
                    "https://engine.netsupport.dk:7080/account/v1/detailecodebtor/" + this.mytoken +
                        "?customernumber=" + this.customernumber 
                )
                .then((responsedebtor) => { 
                    //console.log(responsedebtor.data.economicdebtor);
                    this.debtordata = responsedebtor.data.economicdebtor;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
        },
    }

</script>

<style lang="scss" scoped>
    @import "~@core/scss/base/pages/app-invoice.scss";

        .btn_cls{
            width: 20% !important;
        }
</style>
<style scoped>
    .info-heading{
        font-weight: bold !important;
        font-size: 20px !important;
        margin-bottom: 0 !important;
    }
    .custom-spacing{
        margin: 0.45rem 0 !important;
    }
    .login_logo_img{
        width: 100%;
        max-width: 55px;
    }
    .brand-logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<style lang="scss">
    @media print {

        // Global Styles
        body {
            background-color: transparent !important;
        }
        nav.header-navbar {
            display: none;
        }
        .main-menu {
            display: none;
        }
        .header-navbar-shadow {
            display: none !important;
        }
        .content.app-content {
            margin-left: 0;
            padding-top: 2rem !important;
        }
        footer.footer {
            display: none;
        }
        .card {
            background-color: transparent;
            box-shadow: none;
        }
        .customizer-toggle {
            display: none !important;
        }
        .edit_btn{
            display: none !important;   
        }
        .copy_btn{
            display: none !important; 
        }
        // Invoice Specific Styles
        .invoice-preview-wrapper {
            .row.invoice-preview {
                .col-md-8 {
                    max-width: 100%;
                    flex-grow: 1;
                }

                .invoice-preview-card {
                    .card-body:nth-of-type(2) {
                        .row {
                            > .col-12 {
                                max-width: 50% !important;
                            }

                            .col-12:nth-child(2) {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-end;
                                margin-top: 0 !important;
                            }
                        }
                    }
                }
            }

            // Action Right Col
            .invoice-actions {
                display: none;
            }
        }
    }
</style>
