<template>
    <div>
        <b-card
            no-body
            class="mb-0"
        >
            <div class="m-2">
                <b-row>
                    <b-col
                        cols="12"
                        md="8"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <b-form-input
                            v-model="filterName"
                            id="filterName"
                            class="d-inline-block mr-1"
                            :placeholder="$t('Name')"
                        />
                        <b-form-input
                            v-model="filterNumber"
                            id="filterNumber"
                            class="d-inline-block mr-1"
                            :placeholder="$t('Customer Number')"
                        />
                        <v-select
                            v-model="filterStatus"
                            id="filterStatus"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            :clearable="false"
                            style="min-width: 20%;"
                            class="per-page-selector d-inline-block mx-50"
                            placeholder="Status"
                        >
                            <template #option="{ label }">
                                <span> {{ $t(label) }}</span>
                            </template>
                        </v-select>
                    </b-col>

                    <b-col
                        cols="12"
                        md="4"
                        class="d-flex align-items-center justify-content-end"
                    >
                        <b-button
                            id="updateBTN"
                            variant="primary"
                            @click="updateDebtorlist()"
                        >
                            <span class="text-nowrap">{{ $t('Update Debtors') }}</span>
                        </b-button>
                    </b-col>
                </b-row>
                <br>
                <b-row>
                    <b-col
                        cols="12"
                        sm="8"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label>{{ $t('Pagesize') }}</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label class="mr-50">{{ $t('Action') }}</label>
                        <v-select
                            v-model="filterselectedCustomer"
                            dir="ltr"
                            :options="actionOptions"
                            @input ="multipleAction($event)"          
                            class="w-50"
                            :placeholder="$t('Actions')"
                            label="text"
                        >
                            <template #option="{ text }">
                                <span> {{ $t(text) }}</span>
                            </template>
                        </v-select>&nbsp;
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="4"
                        class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalDebtor"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="debtordata"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
            >
                <template #cell(checked_id)="data" class="check-main">
                    <input type="checkbox" @click="checkCustomer($event)" name="customerID_checked[]" class="check-btn" :value="data.item.Customer_ID" :id="data.item.Customer_ID">
                </template>

                <template #cell(debtorAccount)="data">
                    <span :style="data.item.Enabled == 'N' ? 'color:red' : 'color:green'">
                        {{ data.item.debtorAccount }}
                    </span>
                </template>

                <template #cell(debtorName)="data">
                    <span :style="data.item.Enabled == 'N' ? 'color:red' : 'color:green'">
                        {{ data.item.debtorName }}
                    </span>
                </template>

                <template #cell(kundeLogin)="data">
                    <span :style="data.item.Enabled == 'N' ? 'color:red' : 'color:green'">
                        {{ data.item.kundeLogin }}
                    </span>
                </template>

                <template #cell(Email)="data">
                    <span :style="data.item.Enabled == 'N' ? 'color:red' : 'color:green'">
                        {{ data.item.Email }}
                    </span>
                </template>

                <template #cell(Enabled)="data">
                    <div v-if="data.item.Enabled == 'N'">
                        <span style="color:red;">
                            {{ $t('Disabled') }}
                        </span>
                    </div>
                    <div v-if="data.item.Enabled == 'Y'">
                        <span style="color:green;">
                            {{ $t('Enabled') }}
                        </span>
                    </div>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50">{{ $t('Details') }}</span>
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                        </b-dropdown-item>

                        <b-dropdown-item>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                        </b-dropdown-item>

                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"> {{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select';
    import axios from "axios";
    import i18n from '@/libs/i18n/index';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,

            vSelect,
        },
        data() {
            return {
                debtordata : [],
                tableColumns : [
                    { key: "checked_id", label: "" },
                    { key: 'debtorAccount', label: i18n.t('debtorAccount'), sortable: true },
                    { key: 'debtorName', label: i18n.t('debtorName'), sortable: true },
                    { key: 'kundeLogin', label: i18n.t('CustomerLogin'), sortable: true },
                    { key: 'Email', label: i18n.t('Email'), sortable: true },
                    { key: 'Enabled', label: i18n.t('Enabled / Disabled'), sortable: true },
                    { key: 'actions', label: i18n.t('actions') },
                ],
                perPage: 10,
                currentPage: "1",
                totalDebtor: "",
                dataMeta: { from: 1, to: this.perPage, of: this.totalDebtor },
                perPageOptions: [10, 20, 40, 60, 80, 100],
                statusOptions :["all","enabled","disabled"],
                filterStatus : "all",
                filterName : "",
                filterNumber:"",
                filterCity :"",

                filterselectedCustomer:"",
                actionData : [],

                actionOptions : [
                    {
                        text:"disable",
                        value:"disable"
                    },
                    {
                        text:"enable",
                        value:"enable"
                    },
                    {
                        text:"enable and email",
                        value:"email"
                    },
                    {
                        text:"enable, new password and email",
                        value:"password"
                    },
                ]
            }
        },
        updated() {
            const f = this.perPage * (this.currentPage - 1);
            this.dataMeta.from = f + 1;
            this.dataMeta.to = this.perPage * this.currentPage;
        },
        watch: {
            perPage: function () {
                this.getDebtorlist();
            },
            filterStatus: function (val) {
                this.filterStatus = val;
                this.getDebtorlist();
            },
            filterCity: function (val) {
                this.filterCity = val;
                this.getDebtorlist();
            },
            filterNumber: function (val) {
                this.filterNumber = val;
                this.getDebtorlist();
            },
            filterName: function (val) {
                this.filterName = val;
                this.getDebtorlist();
            },
            currentPage: function (val) {
                this.currentPage = val;
                this.getDebtorlist();
            },
        },
        created(){
            this.mytoken = localStorage.getItem("token");

            if (this.mytoken == "" || this.mytoken == null) {
                localStorage.removeItem("token");
                this.$router.push({ name: "login" });
            }else{
                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/checktoken/"+this.mytoken
                };
                axios(config)
                .then((response) => {
                    console.log("response",response);
                    if(response.data.token.status == true){
                        this.getDebtorlist();
                    }
                })
                .catch(function (error) {
                    if(error.response.status == 404)
                    {
                        localStorage.setItem("token", '');
                        localStorage.removeItem("username");
                        window.location.reload();
                    }
                });
            }
        },
        methods : {
            
            getDebtorlist(){
                this.mytoken = localStorage.getItem("token");

                const offset = this.perPage * (this.currentPage - 1);
                this.filteroffset = offset;

                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/headers/" + this.mytoken +
                    "?offset=" + this.filteroffset +
                    "&pagesize=" + this.perPage +
                    "&name=" + this.filterName +
                    "&number=" + this.filterNumber +
                    "&status=" + this.filterStatus 
                };
                axios(config)
                .then((response) => {
                    this.dataMeta.of = response.data.debtors.MaxRecords;
                    this.totalDebtor = response.data.debtors.MaxRecords;
                    this.debtordata = JSON.parse(
                        JSON.stringify(response.data.debtors.recordset)
                    );
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            updateDebtorlist(){
                 var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/updateall/"+this.mytoken
                };
                axios(config)
                .then((responsedebtor) => {

                    if(responsedebtor.data.debtorUpdate == "done"){
                        
                        alert("WAIT, getting your data...");
                        const btn = document.getElementById("updateBTN");
                        btn.disabled = true;
                        setTimeout(()=>{
                            btn.disabled = false;
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'DebtorList Updated..',
                                    icon: 'CheckCircleIcon',
                                    variant: 'success',
                                    timeout: 5000,
                                },
                            });
                        this.getDebtorlist();
                        }, 5000)
                        
                    }
                })
                .catch(function (error) {
                    console.log(error);   
                });
            },
            checkCustomer(event){
                if(event.target.checked){
                    this.actionData.push(event.target.value);
                }else{
                    this.actionData = this.actionData.filter((f) => f !== event.target.value ? this.actionData : '');
                }
            },
            multipleAction(event){
                if(this.actionData.length == 0){
                    alert('Please Select At Least One Customer');
                }else{
                    let temp = [];

                    this.actionData.forEach(element => {
                        temp.push({
                            customer_ID : element,
                            action : event.value
                        });
                    });
                    var submitData = JSON.stringify({
                        actions : temp
                    });

                    this.mytoken = localStorage.getItem("token");
                    var config = {
                        method: "post",
                        url: "https://engine.netsupport.dk:7080/account/v1/actions/" + this.mytoken,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: submitData,
                    };
                    axios(config)
                    .then((response) => {
                        //console.log(response.data);
                        alert('Action '+response.data.actions);
                        this.actionData.forEach(element => {
                            document.getElementById(element).checked = false;
                        });
                        this.filterselectedCustomer = "";
                        this.getDebtorlist();
                        temp = [];
                        this.actionData = [];
                    })
                    .catch(function (error) {
                        alert("Data not Updated.");
                    });
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
    .check-btn{
        transform: scale(02);
        width: 12px;
        height: 12px;
    }
    // .invoice-filtercheck-select{
    //     width: 40%;
    // }
    .disable_row{
        color: red !important;
    }
    .enable_row{
        color: green !important;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
