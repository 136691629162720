<template>
    <div>
        <div v-if="show" v-for="data in selectedOrder" >
            <div class="modal-mask" >
                <div class="modal-container">
                    <div class="header">
                        <h4>{{$t("Economic Debtor Details")}}</h4>
                    </div>
                    <hr>
                    <div >
                        <div class="display" >
                            <h6>{{$t("Customer Name")}}:</h6>
                            <p>{{data.name}}</p>
                        </div>
                        <div class="display">
                            <h6>{{$t("Email")}}:</h6>
                        </div>
                        <div class="display">
                            <h6>{{$t("Address")}}:</h6>
                        </div>
                        <div class="display" >
                            <h6>{{$t("Currency")}}:</h6>
                        </div>
                        <div class="display">
                            <h6>{{$t("Balance")}}:</h6>
                            <p>{{data.balance}}</p>
                        </div>

                        <div class="display">
                            <h6>{{$t("Due-Amount")}}:</h6>
                            <p>{{data.dueAmount}}</p>
                        </div>
                        <div class="display">
                            <h6>{{$t("Telephone/FaxNumber")}}:</h6>
                        </div>
                        <div class="display">
                            <h6>{{$t("Corporate Idntification Number")}}:</h6>
                            <p>{{data.corporateIdentificationNumber}}</p>
                        </div>
                        <div class="display">
                            <h6>{{$t("E-invoiceing Disabled ByDefault")}}:</h6>
                        </div>
                    </div>
                    <hr>
                    <button @click="closeModal" class="closeBtn">{{$t("Close")}}</button>
                </div>
            </div>
        </div>

        <b-card
            no-body
            class="mb-0"
        >
            <div class="m-2">
                <b-row>
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <b-form-input
                            v-model="filterName"
                            id="filterName"
                            class="d-inline-block mr-1"
                            :placeholder="$t('Name')"
                        />
                        <b-form-input
                            v-model="filterNumber"
                            id="filterNumber"
                            class="d-inline-block mr-1"
                            :placeholder="$t('Customer Number')"
                        />
                    </b-col>

                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-end"
                    >
                        <b-button
                            variant="primary"
                            @click="getDebtorData()"
                        >
                            <span class="text-nowrap">{{ $t('Search') }}</span>
                        </b-button>
                    </b-col>
                </b-row>
                <br>
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label>{{ $t('Pagesize') }}</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <!-- <label class="mr-50">{{ $t('Action') }}</label>
                        <v-select
                            v-model="filterselectedCustomer"
                            dir="ltr"
                            :options="actionOptions"
                            @input ="multipleAction($event)"
                            class="invoice-filtercheck-select"
                            :placeholder="$t('Actions')"
                        >
                            <template #selected-option="{ label }">
                            <span class="text-truncate overflow-hidden">
                                {{ label }}
                            </span>
                            </template> </v-select
                        >&nbsp; -->
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                    >
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            class="mr-1"
                            id="first"
                            @click="whichPage"
                        >
                            {{ $t('First') }}
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            class="mr-1"
                            id="prior"
                            @click="whichPage"
                        >
                            {{ $t('Prior') }}
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            class="mr-1"
                            id="next"
                            @click="whichPage"
                        >
                            {{ $t('Next') }}
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            id="last"
                            @click="whichPage"
                        >
                            {{ $t('Last') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="debtordata"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
                @row-clicked="openModal"

            >
                <!-- <template #cell(checked_id)="data" class="check-main">
                    <input type="checkbox" @click="checkCustomer($event)" name="customerNumber_checked[]" class="check-btn" :value="data.item.customerNumber" :id="data.item.customerNumber">
                </template> -->

                <template #cell(customerNumber)="data">

                    <b-link :to="'/economic-debtor-details/'+ data.item.customerNumber" class="font-weight-bold" >
                        {{ data.item.customerNumber }}
                    </b-link>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item :to="'/economic-debtor-details/'+ data.item.customerNumber" >
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50">{{ $t('Details') }}</span>
                        </b-dropdown-item>

                        <!-- <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                        </b-dropdown-item>

                        <b-dropdown-item>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                        </b-dropdown-item> -->

                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row >
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"

                    >
                        <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select';
    import axios from "axios";
    import i18n from '@/libs/i18n/index';
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,

            vSelect,
        },
        directives: {
            Ripple
        },
        data() {
            return {
                debtordata : [],
                paginationdata : [],
                tableColumns : [
                    // { key: "checked_id", label: "" },
                    { key: 'customerNumber', label: i18n.t('customerNumber'), sortable: true },
                    { key: 'name', label: i18n.t('name'), sortable: true },
                    { key: 'city', label: i18n.t('city'), sortable: true },
                    { key: 'balance', label: i18n.t('balance'), sortable: true },
                    { key: 'dueAmount', label: i18n.t('dueAmount'), sortable: true },
                    { key: 'actions', label: i18n.t('actions') },
                ],
                filteroffset:null,
                perPage: 10,
                currentPage: 0,
                totalUser:null,
                totalPage:null,
                totalDebtor: "",
                countList:["1","2","3","4","5","6","7","8","9","10"],
                dataMeta: { from: 1, to: this.perPage, of: this.totalDebtor },
                perPageOptions: [10, 20, 40, 60, 80, 100],
                filterName : "",
                filterNumber:"",
                bodyURL : "",
                show:true,
                selectedOrder:[],
                filterselectedCustomer:"",
                actionData : [],
                actionOptions :[
                    "enable",
                    "disable",
                    "new password",
                    "email"
                ],
            }
        },
        updated() {
            const f = this.perPage * (this.currentPage - 1);
            this.dataMeta.from = f + 1;
            this.dataMeta.to = this.perPage * this.currentPage;
        },
        watch: {
            perPage: function () {
                this.getDebtorData();
            },
            filterNumber: function (val) {
                console.log('val',val);
                this.filterNumber = val;
                this.getDebtorData(val);
            },
            filterName: function (val) {
                this.filterName = val;
                this.getDebtorData(val);
            },
            currentPage: function (val) {
                this.currentPage = val;
                this.getDebtorData(val);
            },
        },
        created(){

            this.mytoken = localStorage.getItem("token");

            if (this.mytoken == "" || this.mytoken == null) {
                localStorage.removeItem("token");
                this.$router.push({ name: "login" });
            }else{
                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/checktoken/"+this.mytoken
                };
                axios(config)
                .then((response) => {
                    if(response.data.token.status == true){
                        this.getDebtorData(0);
                    }
                })
                .catch(function (error) {
                    if(error.response.status == 404)
                    {
                        localStorage.setItem("token", '');
                        localStorage.removeItem("username");
                        window.location.reload();
                    }
                });
            }

        },
        methods : {
            getDebtorData(offsetVal){

                this.mytoken = localStorage.getItem("token");

                const offset = this.perPage * (this.currentPage - 1);
                this.filteroffset = offset;
                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/economicdebtors/" + this.mytoken +
                    "?offset=" + offsetVal +
                    "&pagesize=" + this.perPage +
                    "&name=" + this.filterName +
                    "&number=" + this.filterNumber
                };
                axios(config)
                .then((response) => {
                    console.log("res",response);
                    this.debtordata = JSON.parse(
                        JSON.stringify(response.data.economic.collection)
                    );
                    this.paginationdata = response.data.economic.pagination;
                    //first button
                    this.totalUser=response.data.economic.pagination.results;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            closeModal(){
                this.show=false;
            },
            showDataInModal(customerNumber){
              let customerOrder=this.debtordata.find((data)=>{
                return data.customerNumber==customerNumber;
              })
              if(customerOrder){
                this.selectedOrder.push(customerOrder);
                this.show=true;
              }else{
                console.log("data is not match");
              }
            },

            openModal(item){
                this.showDataInModal(item.customerNumber);
            },
            whichPage(e){
                if(e.target.id == "first"){
                    this.currentPage = 0;
                    this.getdebtData(this.currentPage)
                }else if(e.target.id == "prior"){
                    this.currentPage -= 1
                    if (this.currentPage < 0){
                      this.currentPage = 0
                      this.getdebtData(this.currentPage)
                    }else{
                      this.getdebtData(this.currentPage)
                    }

                }else if(e.target.id == "next") {
                    this.currentPage += 1
                  let maxPage = Math.ceil(this.totalUser / this.perPage) - 1
                  if(this.currentPage > maxPage) {
                    this.currentPage = maxPage
                    this.getdebtData(this.currentPage)
                  }else{
                    this.getdebtData(this.currentPage)
                  }

                }else if(e.target.id == "last"){
                    this.currentPage = Math.ceil(this.totalUser/this.perPage)-1;
                    this.getDebtorData(this.totalPage)
                }
            },

            getdebtData(offset){
                this.filteroffset=offset;
                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/economicdebtors/" + this.mytoken +
                    "?offset=" + offset +
                    "&pagesize=" + this.perPage +
                    "&name=" + this.filterName +
                    "&number=" + this.filterNumber
                };
                axios(config)
                .then((response) => {

                    this.debtordata = JSON.parse(
                        JSON.stringify(response.data.economic.collection)
                    );
                    this.totalUser=response.data.economic.pagination.results
                })
                .catch(function (error) {
                    console.log(error);
                });


            },

            loadmyPagination(){
                let start=((this.currentPage-1)*this.perPage)
                let end= start + this.perPage;
                this.debtordata=this.debtordata.slice(start,end);
            },

            checkCustomer(event){
                if(event.target.checked){
                    this.actionData.push(event.target.value);

                }else{
                    this.actionData = this.actionData.filter((f) => f !== event.target.value ? this.actionData : '');
                }
            },

            multipleAction(event){
                if(this.actionData.length == 0){
                    alert('Please Select At Least One Customer');
                }else{
                    let temp = [];

                    this.actionData.forEach(element => {
                        temp.push({
                            customerNumber : element,
                            action : event
                        });
                    });
                    var submitData = JSON.stringify({
                        actions : temp
                    });

                    console.log(submitData);

                    // this.mytoken = localStorage.getItem("token");
                    // var config = {
                    //     method: "post",
                    //     url: "https://engine.netsupport.dk:7080/account/v1/actions/" + this.mytoken,
                    //     headers: {
                    //         "Content-Type": "application/json",
                    //     },
                    //     data: submitData,
                    // };
                    // axios(config)
                    // .then((response) => {
                    //     //console.log(response.data);
                    //     alert('Action '+response.data.actions);
                    //     this.actionData.forEach(element => {
                    //         document.getElementById(element).checked = false;
                    //     });
                    //     this.filterselectedCustomer = "";
                    //     this.getDebtorData();
                    //     temp = [];
                    //     this.actionData = [];
                    // })
                    // .catch(function (error) {
                    //     alert("Data not Updated.");
                    // });
                }
            }

        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
    .check-btn{
        transform: scale(02);
        width: 12px;
        height: 12px;
    }
    .invoice-filtercheck-select{
        width: 40%;
    }

    .modal-mask{
        position:fixed;
        inset:0;
        background:rgba(0,0,0,0.6);
        display:flex;
        align-items: center;
        justify-content: center;
        z-index: 9999999999999999999 !important;
    }
    .modal-container{
        background:#f8f8f8;
        padding:24px;
        width:100vw;
        max-width:700px;
        border-radius: 8px;
        border:none;
    }

    .closeBtn{

        padding: 6px 17px;
        cursor: pointer;
        border: none;
        font-size:16px;
        border-radius: 7px;
        background-color: #2d838e;
        color: white;
        margin-left: 560px;
        margin-top: 10px

        }
        .closeBtn:hover{
            background-color: #3695a1;
        }

        .detail{
            text-align:left;

            margin: 0;
        }

        .display{
            display:flex;
            margin-bottom: 5px;;

        }

        .display h6{
            width:50%;
            color:rgb(136, 134, 134);
        }

        .header h3{
            color:rgb(92, 91, 91);

        }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
