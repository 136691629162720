<template>
    <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT  © {{ new Date().getFullYear() }}
            <b-link
                class="ml-25"
                href="https://account.integreat.dk/"
                target="_blank"
            >{{ $t('Portal Manager') }}</b-link>
            <span class="d-none d-sm-inline-block">, All rights Reserved.</span>
            <span class="d-none d-sm-inline-block">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
            <span class="d-none d-sm-inline-block"> v.2022-0625-1230</span>
        </span>
    </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
//import moment from "moment"

export default {
  components: {
    BLink,
  },
  created(){
   //console.log(moment().format('yyyy-MMDD-hhmm'));
  }
}
</script>
