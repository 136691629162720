import i18n from "../../libs/i18n/index"; // import VueI18n instance

export default [
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "Economic",
    route: "debtors",
    icon: "UsersIcon",
    children: [
      {
        title: "Debtors",
        route: "debtors",
      },
    //   {
    //     title: "Debtor Details",
    //     route: "economic-debtor-details",
    //   },
    ],
  },
  {
    title: "Customer Portal",
    route: "debtor-list",
    icon: "UsersIcon",
    children: [
      {
        title: "Debtor List",
        route: "debtor-list",
      },
      // {
      //     title: 'Debtor Details',
      //     route: 'debtor-details',
      // },
      // {
      //     title: 'Invoices',
      //     route: 'invoice-list',
      // }
    ],
  },
];
