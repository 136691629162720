<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />

                <h2 class="brand-text text-primary ml-1">
                    {{ $t('Portal Manager') }}
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center justify-content-center p-5 flex-column">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5 mb-5"
                >
                    <b-img fluid :src="imgUrl" alt="Vector"/>
                </div>
                <div class="align-items-center justify-content-center custom-width text-block">
                    <p>{{ info_help_text }}</p> 
                </div>
            </b-col>
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t('Welcome to Portal Manager') }}! 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t('Hii ') }} {{ this.userdata.name }},<br>
                        {{ $t('Please Accept the Key') }}
                    </b-card-text>
                </b-col>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import VuexyLogo from "@core/layouts/components/Logo.vue";
    import {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
    } from "bootstrap-vue";
    import { togglePasswordVisibility } from "@core/mixins/ui/forms";
    //import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import store from "@/store/index";
    import axios from "axios";
    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardText,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver,
        },
        props:{
            ecoURL : String,
            cookie : String,
            userdata : Object,
            CVR : String
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                info_help_text : "",
                sideImg: require("@/assets/images/pages/forKonto-light.png"),
            };
        },
        created(){
            //console.log(this.userdata);
            if(this.ecoURL){
                window.open(this.ecoURL,'_blank');
            }
            this.infohelpText();
            this.interval = setInterval(() => this.acceptanceMethod(), 5000);
        },
        
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === "dark") {
                    this.sideImg = require("@/assets/images/pages/forKonto-dark.png");
                    return this.sideImg;
                }
                return this.sideImg;
            },
        },
        methods: {
            infohelpText(){
                var config = {
                    method: "get",
                    url: "https://engine.netsupport.dk:7080/account/v1/getinfotext?article=welcome"
                };
                axios(config)
                .then((response) => {
                    if(response.data.memo){
                        this.info_help_text = response.data.memo;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            acceptanceMethod(){
                axios(
                    "https://engine.netsupport.dk:7080/account/v1/startproces?cvr=" + this.CVR +
                    "&cookie=" + this.cookie
                )
                .then((response) => {
                    if(response.data.status == "true"){
                        clearInterval(this.interval);
                        this.$router.push({ name: "login" });
                    }else{
                        // this.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: 'Please Accept a Login to the Economic System.',
                        //         icon: 'EditIcon',
                        //         variant: 'success',
                        //         timeout: 2000,
                        //     }, 
                        // });
                        alert('Please Accept a Login to the Economic System');
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                });
            }       
        },
    };
</script>

<style lang="scss">
    @import "@core/scss/vue/pages/page-auth.scss";
</style>
<style scoped>
    .custom-width{
        width: 85%;
    }
    .custom-text{
        font-weight: bold;
        font-size: medium;
    }
    .text-block {
        white-space: pre;
    }
</style>